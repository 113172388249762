<template>
    <section v-if="data?.Series" class="flex flex-col text-base gap-4">
        <ManufacturerSeriesHeader />
        <ManufacturerSeriesCategories />
        <h2 class="container-left my-0">{{ $t("Global.Parts") }}</h2>
        <CategoryFilters />
        <CategoryParts :series="data?.Series" />
    </section>
</template>
<script setup lang="ts">
const route = useRoute();
const { t } = useI18n();
const manufacturer = useStateManufacturer();
const category = useStateCategory();
const { company, basePartNumber } = route.params;
const filterOptions = useStateFilterOptions();

type ProductSeriesResponse = {
    Series: ProductSeries;
    Manufacturer: ManufacturerDetails;
    Category: Category;
};

const { data } = await useFetchLocaleApi<ProductSeriesResponse>("api/product-series/one", {
    query: { virtualPath: company, basePartNumber: basePartNumber },
    onResponse({ response }) {
        manufacturer.value = response._data.Manufacturer;
        category.value = response._data.Category;
    },
    onResponseError({ response, error }) {
        throw showError({ statusCode: response.status, statusMessage: error?.message, ...error });
    },
});

const title = computed(() => t("ProductSeries.TitleFormat", [manufacturer.value?.DisplayName, basePartNumber]));
const description = computed(() =>
    t("ProductSeries.MetaDescriptionFormat", [manufacturer.value?.DisplayName, basePartNumber])
);

useSeoMeta({ title, ogTitle: title, description, ogDescription: description });

await useFetchLocaleApi<FilterOption[]>(`api/product-series/filters`, {
    query: { productSeriesId: data.value?.Series.Id, categoryId: category.value?.Id },
    onResponse({ response }) {
        filterOptions.value = response._data || [];
    },
});
</script>

<template>
    <PageHeader wide>
        <h1>
            <NuxtLink :to="localePath('/manufacturers/' + company)">
                {{ manufacturer?.DisplayName }}
            </NuxtLink>
            <span class="ml-2">{{ seriesHeading }}</span>
        </h1>
    </PageHeader>
</template>
<script setup lang="ts">
const { t } = useI18n();
const route = useRoute();
const localePath = useLangPath();
const manufacturer = useStateManufacturer();
const { company, basePartNumber } = route.params;

const seriesHeading = computed(() => t("ProductSeries.SeriesHeading", [basePartNumber]));
</script>

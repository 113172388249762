<template>
    <div v-if="category" class="container-left flex flex-wrap gap-2 items-center">
        <template v-for="parent in category.Parents" :key="parent.Id">
            <NuxtLink :to="localePath('/part-category/' + parent.Path)">{{ parent.Name }}</NuxtLink>
            <i class="fass fa-chevron-right"></i>
        </template>
        <NuxtLink :to="localePath('/part-category/' + category.Path)">{{ category.Name }}</NuxtLink>
    </div>
</template>
<script setup lang="ts">
const category = useStateCategory();
const localePath = useLangPath();
</script>
